import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';

function getFormattedCustomFiltersList (array: IFilter[]): { name: string; values: IFilter[] }[] {
  const newArray: Array<{ name: string; values: IFilter[] }> = [];

  array.forEach((obj) => {
    const categoryName = obj.filterTemplate.split('#')[1];

    if (!newArray.some((item) => item.name === categoryName)) {
      newArray.push({ name: categoryName, values: [] });
    }

    const newObj = { ...obj };
		newArray.find((item) => item.name === categoryName)!.values.push(newObj);
  });

  return newArray;
}

export {
  getFormattedCustomFiltersList
};
